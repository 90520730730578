import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    divider: {
        borderColor: '$colorBorderInput',
        borderBottomWidth: '$borderWidth05',
    },
    touchable: {
        flexDirection: 'row',
    },
    text: {
        paddingTop: '$space05',
    },
    iconContainerWithText: {
        paddingTop: () => StyleSheet.value('$space2 + $space05'),
    },
});
