const locale = (locale) => {
    try {
        Intl.getCanonicalLocales(locale);
    }
    catch (error) {
        throw new Error(`This is not a valid locale: ${locale}`);
    }
    return locale;
};
export { locale };
