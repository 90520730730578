import { bootstrap as messagingBootstrap } from "@lookiero/messaging";
import { SYNC_TRANSLATION } from "../../domain/translation/command/syncTranslation";
import { sync } from "../../domain/translation/model/translation";
import { viewTranslationByLocaleHandler, VIEW_TRANSLATION_BY_LOCALE, } from "../../projection/translation/viewTranslationByLocale";
import { viewTranslationKeyByLocaleHandler, VIEW_TRANSLATION_KEY_BY_LOCALE, } from "../../projection/translation/viewTranslationKeyByLocale";
import { getTranslation, saveTranslation } from "../domain/translation/model/storageTranslations";
import { read as asyncStorageRead, write as asyncStorageWrite } from "../persistence/asyncStorageStorage";
import { storageTranslationByLocaleView } from "../projection/translation/storageTranslationView";
const baseBootstrap = ({ messaging, domain }) => ({ fetchTranslation, read = asyncStorageRead, write = asyncStorageWrite }) => messaging
    .query(VIEW_TRANSLATION_KEY_BY_LOCALE, viewTranslationKeyByLocaleHandler, {
    domain,
})
    .query(VIEW_TRANSLATION_BY_LOCALE, viewTranslationByLocaleHandler, {
    view: storageTranslationByLocaleView({ read }),
})
    .command(SYNC_TRANSLATION, sync, { fetchTranslation })(getTranslation, saveTranslation, { write })
    .build();
const bootstrap = ({ domain }) => baseBootstrap({ domain, messaging: messagingBootstrap() });
export { baseBootstrap, bootstrap };
