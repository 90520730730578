import { syncTranslation } from "@lookiero/i18n";
import { useCommand } from "@lookiero/messaging-react";
import { useCallback, useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
const useSyncTranslationForLocale = ({ locale, contextId }) => {
    const localeRef = useRef();
    const [commandBus, status] = useCommand({ contextId });
    const sync = useCallback(async () => await commandBus(syncTranslation({ aggregateId: uuid(), locale })), [locale, commandBus]);
    useEffect(() => {
        if (locale && localeRef.current !== locale) {
            sync();
            localeRef.current = locale;
        }
    }, [locale, sync]);
    return status;
};
export { useSyncTranslationForLocale };
