import StyleSheet from 'react-native-extended-stylesheet';
const style = StyleSheet.create({
    base: {
        alignContent: 'center',
        paddingLeft: '$space6',
        paddingRight: '$space6',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        height: '$space16',
    },
    container: {
        height: '$space16',
    },
    outfittery: {
        borderRadius: '$borderRadius1',
    },
    lookiero: {
        borderRadius: '$borderRadiusFull',
    },
    busy: {
        height: '300%',
        left: 0,
        opacity: 0.7,
        position: 'absolute',
        top: 0,
    },
    busyIcon: {
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    content: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    fit: {
        alignSelf: 'center',
    },
    small: {
        height: '$space12',
    },
});
const buttonVariantStyle = StyleSheet.create({
    primary: {},
    'primary-light': {},
    secondary: {
        borderColor: '$colorBorderActionSecondary',
        borderWidth: '$borderWidth2',
        borderStyle: '$borderStyle',
        paddingLeft: '$space6',
        paddingRight: '$space6',
        height: '$space16',
    },
    disabled: {
        backgroundColor: '$colorBgActionPrimaryDisabled',
        borderWidth: 0,
        paddingLeft: '$space6',
        paddingRight: '$space6',
    },
});
const contentVariantStyleRecord = {
    primary: {
        color: '$colorTextActionPrimary',
    },
    'primary-light': {
        color: '$colorTextActionSecondary',
    },
    secondary: {
        color: '$colorTextActionSecondary',
    },
    disabled: {
        color: '$colorTextActionDisabled',
    },
};
const contentVariantStyle = StyleSheet.create(contentVariantStyleRecord);
export { style, buttonVariantStyle, contentVariantStyle, contentVariantStyleRecord };
