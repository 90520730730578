import { ALIGN, COLOR } from '../../../../hooks';
import { OPTION_VARIANT } from '../Option.definition';
import { style } from '../Option.style';
export const getVariant = (variant, others = {}) => {
    const { checked, disabled, small } = others;
    const color = disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT;
    const commonStyleCardAndPill = {
        backgroundColor: checked ? COLOR.BG_INTERACTIVE_SELECTED : COLOR.BG_INTERACTIVE_ACTIVE,
        label: {
            action: !small,
            align: ALIGN.CENTER,
            color: color,
            detail: small,
            level: small ? 1 : 3,
        },
        visibleSwitch: false,
    };
    switch (variant) {
        case OPTION_VARIANT.BUTTON:
            return {
                backgroundColor: COLOR.BG_BASE,
                borderColor: checked ? COLOR.BORDER_INTERACTIVE_SELECTED : COLOR.BORDER_INTERACTIVE,
                label: {
                    color: color,
                    detail: true,
                    level: 1,
                },
                style: [style.button, checked && style.buttonChecked],
                visibleSwitch: true,
            };
        case OPTION_VARIANT.CARD:
            return Object.assign(Object.assign({}, commonStyleCardAndPill), { style: [style.card, small && style.cardSmall] });
        case OPTION_VARIANT.PILL:
            return Object.assign(Object.assign({}, commonStyleCardAndPill), { style: [style.pill, small && style.pillSmall] });
        default: {
            return {};
        }
    }
};
