import { CheckoutStatus, PaymentMethod, Section } from "@lookiero/payments-front";
const LOOK_IN_BACK_BASE_URL = "/direct-buy/v1";
const COMPLETE_YOUR_LOOK_BASE_URL = "/looks/v2";
const CATEGORIES_BASE_URL = "/categories/v2";
const SALES_BASE_URL = "/sales/v1";
const ONBOARDING_BASE_URL = "/onboardings/v1";
const CHECKOUT_BASE_URL = "/web/api/checkout/v1";
const PAYMENTS_BASE_URL = "/web/api/payments/v1";
const USERS_BASE_URL = "/users/v1";
const TRENDING_FOR_YOU_BASE_URL = "/trending/v1";
// TODO: check typing
const PAYMENTS_CONFIG = {
    CheckoutStatus,
    PaymentMethod,
    Section,
};
/**
 * TODO: Some types should extracted to Domain (currency, image perspectives, country codes, ...)
 */
const CURRENCY = {
    // @see ISO 4217 Currency codes: https://www.iso.org/iso-4217-currency-codes.html
    BE: "EUR",
    DE: "EUR",
    ES: "EUR",
    FR: "EUR",
    GB: "GBP",
    IT: "EUR",
    LU: "EUR",
    PT: "EUR",
    AT: "EUR",
    NL: "EUR",
    SE: "SEK",
    CH: "CHF",
};
// eslint-disable-next-line @typescript-eslint/naming-convention
var IMAGE_PERSPECTIVE;
(function (IMAGE_PERSPECTIVE) {
    // @see https://github.com/lookiero/catalog-back/blob/2d794574c6166061d467596e577f882475fa47a3/src/main/java/com/lookiero/catalog/pim/domain/media/model/MediaPerspective.java#L3
    IMAGE_PERSPECTIVE["MAIN"] = "MAIN";
    IMAGE_PERSPECTIVE["BACK"] = "BACK";
    IMAGE_PERSPECTIVE["COLLAGE"] = "COLLAGE";
    IMAGE_PERSPECTIVE["DETAIL"] = "DETAIL";
    IMAGE_PERSPECTIVE["FRONT"] = "FRONT";
    IMAGE_PERSPECTIVE["FRONT_FAR"] = "FRONT_FAR";
    IMAGE_PERSPECTIVE["INSPIRATIONAL"] = "INSPIRATIONAL";
    IMAGE_PERSPECTIVE["LABEL"] = "LABEL";
    IMAGE_PERSPECTIVE["OTHER"] = "OTHER";
    IMAGE_PERSPECTIVE["SIDE"] = "SIDE";
})(IMAGE_PERSPECTIVE || (IMAGE_PERSPECTIVE = {}));
const LOCALE = {
    // @see IETF BCP 47: http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
    BE: "fr-BE",
    DE: "de-DE",
    ES: "es-ES",
    FR: "fr-FR",
    GB: "en-GB",
    IT: "it-IT",
    LU: "fr-LU",
    PT: "pt-PT",
    AT: "de-AT",
    NL: "nl-NL",
    SE: "sv-SE",
    CH: "de-CH",
};
const TLD = {
    DE: "de",
    ES: "es",
    FR: "fr",
    GB: "co.uk",
    IT: "it",
    LU: "be",
    BE: "be",
    PT: "pt",
    AT: "at",
    NL: "nl",
    SE: "se",
    CH: "ch",
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const SCREEN_SIZE = {
    S: "S",
    M: "M",
    L: "L",
};
var OS;
(function (OS) {
    OS["WEB"] = "web";
    OS["ANDROID"] = "android";
    OS["IOS"] = "ios";
})(OS || (OS = {}));
const DEFAULT_COUNTRY_CODE = "ES";
const DEFAULT_CURRENCY = CURRENCY["ES"];
const DEFAULT_LOCALE = LOCALE["ES"];
const DEFAULT_TLD = TLD["ES"];
const DEFAULT_SCREEN_SIZE = SCREEN_SIZE["S"];
const CONTENT_MAX_WIDTH = 1280;
export { LOOK_IN_BACK_BASE_URL, COMPLETE_YOUR_LOOK_BASE_URL, CATEGORIES_BASE_URL, SALES_BASE_URL, ONBOARDING_BASE_URL, CHECKOUT_BASE_URL, PAYMENTS_BASE_URL, USERS_BASE_URL, TRENDING_FOR_YOU_BASE_URL, PAYMENTS_CONFIG, CURRENCY, IMAGE_PERSPECTIVE, LOCALE, SCREEN_SIZE, OS, DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY, DEFAULT_LOCALE, DEFAULT_SCREEN_SIZE, CONTENT_MAX_WIDTH, TLD, DEFAULT_TLD, };
