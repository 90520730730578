import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    input: Object.assign({ color: '$colorText', flex: 0, height: '$inputSize' }, Platform.select({
        web: {
            outlineWidth: 0,
            height: undefined,
        },
    })),
    disabled: {
        color: '$colorTextDisabled',
    },
    multiline: Object.assign(Object.assign({ paddingTop: '$space4' }, Platform.select({
        web: {
            flex: 'unset',
        },
        ios: {
            paddingTop: 18,
        },
    })), { paddingBottom: '$space4', textAlignVertical: 'top' }),
});
