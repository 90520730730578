var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef } from 'react';
import { stylesheetFont } from '../../../../../helpers';
import { ALIGN, FLEX_DIRECTION } from '../../../../../hooks';
import { Input, Text, View } from '../../../../primitives';
import { style } from './Phone.style';
const Phone = forwardRef((_a, ref) => {
    var { color, prefix } = _a, others = __rest(_a, ["color", "prefix"]);
    return (React.createElement(View, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW },
        prefix && (React.createElement(Text, { color: color, detail: true, level: 1, style: [stylesheetFont({ nameSpace: '$fontInput' }).font, style.prefix] }, prefix)),
        React.createElement(Input, Object.assign({}, others, { ref: ref, style: style.input }))));
});
Phone.displayName = 'InputFieldPhone';
export { Phone };
