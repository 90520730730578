import React from "react";
import Animated, { useAnimatedStyle, withTiming } from "react-native-reanimated";
import { Icon } from "@lookiero/aurora";
import { style } from "./AnimatedChecked.style";
const AnimatedChecked = ({ checked }) => {
    const inputOptionViewAnimatedStyle = useAnimatedStyle(() => ({
        opacity: withTiming(checked ? 1 : 0),
    }), [checked]);
    const overlayAnimatedStyle = useAnimatedStyle(() => ({
        opacity: withTiming(checked ? 1 : 0),
    }), [checked]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Animated.View, { style: [style.overlay, overlayAnimatedStyle], testID: "animated-checked-overlay" }),
        React.createElement(Animated.View, { style: [style.inputOptionView, inputOptionViewAnimatedStyle], testID: "animated-checked" },
            React.createElement(Icon, { name: "tick" }))));
};
export { AnimatedChecked };
