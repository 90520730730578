import { useQuery } from "@lookiero/messaging-react";
import { viewScreenBySlugAndCustomerId } from "../../../../projection/screen/viewScreenBySlugAndCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
const useViewScreenBySlugAndCustomerId = ({ slug, customerId, experiment }) => useQuery({
    query: viewScreenBySlugAndCustomerId({ slug: slug, customerId, experiment }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(slug),
    },
});
export { useViewScreenBySlugAndCustomerId };
