import React from "react";
import { View } from "react-native";
import { AnswersProvider, QuestionType, QuestionItemProvider, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { HostDefaultQuestionItem } from "./HostDefaultQuestionItem";
import { HostScreenQuestionItem } from "./HostScreenQuestionItem";
import { Question } from "./Question";
const NullQuestionItem = () => null;
const questionItems = {
    [QuestionType.HOST_DEFAULT]: HostDefaultQuestionItem,
    [QuestionType.HOST_SCREEN]: HostScreenQuestionItem,
    [QuestionType.HOST_SCREEN_WITH_SUMMARY]: NullQuestionItem,
    [QuestionType.IMAGE]: NullQuestionItem,
    [QuestionType.ROUNDED_BUTTON]: NullQuestionItem,
    [QuestionType.ROUNDED_BUTTON_WITH_ICON_ON_SELECTED]: NullQuestionItem,
    [QuestionType.SQUARED_BUTTON]: NullQuestionItem,
    [QuestionType.HOST_SELECT]: NullQuestionItem,
    [QuestionType.OPTION]: NullQuestionItem,
    [QuestionType.HOST_HEIGHT_METRIC]: NullQuestionItem,
    [QuestionType.HOST_WEIGHT_METRIC]: NullQuestionItem,
    [QuestionType.HOST_HEIGHT_IMPERIAL]: NullQuestionItem,
    [QuestionType.HOST_WEIGHT_IMPERIAL]: NullQuestionItem,
    [QuestionType.HOST_RANK]: NullQuestionItem,
    [QuestionType.CHECKBOX]: NullQuestionItem,
    [QuestionType.HOST_DATE]: NullQuestionItem,
    [QuestionType.HOST_IMAGE_UPLOAD]: NullQuestionItem,
    [QuestionType.SOCIAL_NETWORKS_INPUT]: NullQuestionItem,
    [QuestionType.STRIKE_OUT_AVOID_BUTTON_WITH_ICON]: NullQuestionItem,
    [QuestionType.STRIKE_OUT_AVOID_BUTTON]: NullQuestionItem,
    [QuestionType.HOST_INPUT_PILLS]: NullQuestionItem,
    [QuestionType.STRIKE_OUT_BUTTON_WITH_COLOR]: NullQuestionItem,
    [QuestionType.STRIKE_OUT_BUTTON_WITH_PRINT]: NullQuestionItem,
    [QuestionType.BUTTON_WITH_ICON]: NullQuestionItem,
};
const Questions = ({ questions, answers, answersStatus }) => (React.createElement(View, { testID: "style-profile-questions" },
    React.createElement(QuestionItemProvider, { questionItems: questionItems },
        React.createElement(AnswersProvider, { answers: answers, answersStatus: answersStatus, questions: questions }, questions.map((question) => (React.createElement(Question, { key: question.id, question: question, questionParentId: "" })))))));
export { Questions };
