import { CommandStatus } from "@lookiero/messaging-react";
import React, { useCallback } from "react";
import { IntlProvider, } from "react-intl";
import { i18nRoot } from "../../i18nRoot";
const i18n = ({ fetchTranslation, read, write, domain }) => {
    const I18nRoot = i18nRoot({ fetchTranslation, read, write, domain });
    // eslint-disable-next-line react/display-name, react/prop-types
    return ({ locale, children, loader, onError, includeDevTools }) => {
        // eslint-disable-next-line react/display-name, react/prop-types
        const I18nRootChildren = ({ locale, translation, syncStatus }) => {
            const handleI18nError = useCallback((error) => {
                if (syncStatus === CommandStatus.LOADING) {
                    return;
                }
                onError === null || onError === void 0 ? void 0 : onError(error);
            }, [syncStatus]);
            return translation ? (
            // eslint-disable-next-line react/prop-types
            React.createElement(IntlProvider, { key: locale, locale: locale, messages: translation.messages, onError: handleI18nError }, children)) : (loader);
        };
        return (React.createElement(I18nRoot, { includeDevTools: includeDevTools, locale: locale }, I18nRootChildren));
    };
};
export { i18n };
