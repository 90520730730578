import { i18n } from "@lookiero/i18n-react";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { bootstrap as styleProfileBootstrap } from "./src/infrastructure/delivery/bootstrap";
import { root } from "./src/infrastructure/ui/integration/Root";
import { nextBestActionRoot, } from "./src/infrastructure/ui/integration/nextBestAction/NextBestActionRoot";
const bootstrap = ({ apiUrl, getAuthToken, sentry, translations, kameleoon, basePath }) => {
    const { Component: Messaging } = styleProfileBootstrap({ apiUrl, getAuthToken });
    const I18n = i18n({
        fetchTranslation: fetchTranslations({ translations }),
        domain: "StyleProfileI18n",
    });
    return {
        root: root({ Messaging, I18n, sentry, kameleoon, apiUrl, basePath, getAuthToken }),
        nextBestActionRoot: nextBestActionRoot({ Messaging, I18n, sentry, kameleoon, apiUrl, getAuthToken }),
    };
};
export { Country, Segment, bootstrap, translationExternalEndpoint };
