import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, borderRadiusFull, colorBgInteractiveSelected, colorBgOpacityLight, inputOptionSize, space2 } = theme();
const style = StyleSheet.create({
    inputOptionView: {
        alignItems: "center",
        backgroundColor: colorBgInteractiveSelected,
        borderRadius: borderRadiusFull,
        height: inputOptionSize,
        justifyContent: "center",
        position: "absolute",
        right: space2,
        top: space2,
        width: inputOptionSize,
    },
    overlay: {
        backgroundColor: colorBgOpacityLight,
        borderRadius: borderRadius3,
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    },
});
export { style };
