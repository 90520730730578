/* eslint-disable react/prop-types */
// TODO properly export MessagingRoot from @lookiero/messaging-react
import React, { useCallback } from "react";
import { Platform } from "react-native";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Locale } from "@lookiero/sty-psp-locale";
import { sentryLoggerHOC, sentryLogger } from "@lookiero/sty-psp-logging";
import { Routing } from "../routing/Routing";
const root = ({ Messaging, I18n, development, sentry, kameleoon: kameleoonConfig, apiUrl, getAuthToken, basePath, }) => {
    const logger = sentryLogger(sentry);
    const kameleoon = kameleoonConfig();
    const Root = ({ customer, layout, locale = Locale.en_GB, lookAndLike, androidInAppBrowser = false, onBack, useRoutes = reactRouterUseRoutes, }) => {
        const handleOnI18nError = useCallback((error) => (development ? void 0 : logger.captureException(error)), []);
        return (React.createElement(Messaging, { includeReactQueryDevTools: Platform.OS === "web" },
            React.createElement(Routing, { I18n: I18n, androidInAppBrowser: androidInAppBrowser, apiUrl: apiUrl, basePath: basePath, customer: customer, getAuthToken: getAuthToken, kameleoon: kameleoon, layout: layout, locale: locale, lookAndLike: lookAndLike, useRoutes: useRoutes, onBack: onBack, onI18nError: handleOnI18nError })));
    };
    const hoc = sentryLoggerHOC({ logger });
    /**
     * It's important to not call sentryLoggerHOC each time this function gets called.
     * That's why its called outside this method.
     */
    return ({ customerId }) => hoc({ customerId })(Root);
};
export { root };
