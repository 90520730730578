import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space4, colorText } = theme();
const style = StyleSheet.create({
    checkbox: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
    },
    text: {
        color: colorText,
        flex: 1,
        marginLeft: space4,
    },
});
export { style };
