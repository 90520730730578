/* eslint-disable react/prop-types */
import { PortalProvider } from "@gorhom/portal";
import React, { useMemo } from "react";
import { sentryLogger, sentryLoggerHOC } from "@lookiero/sty-psp-logging";
import { NextBestAction, } from "../../views/nextBestAction/NextBestAction";
import { nextBestActionProviders } from "./NextBestActionProviders";
const nextBestActionRoot = ({ Messaging, I18n, kameleoon: kameleoonConfig, sentry, development, apiUrl, getAuthToken, androidInAppBrowser, }) => {
    const logger = sentryLogger(sentry);
    const kameleoon = kameleoonConfig();
    const onI18nError = (error) => (development ? void 0 : logger.captureException(error));
    const Providers = nextBestActionProviders({
        apiUrl,
        getAuthToken,
        I18n,
        kameleoon,
        onI18nError,
        androidInAppBrowser,
    });
    const NextBestActionRootHost = ({ children }) => (React.createElement(Messaging, null,
        React.createElement(PortalProvider, { rootHostName: "StyleProfile" }, children)));
    const NextBestActionRoot = ({ customer, locale, onCompleted, onReady, onNavigateToStyleProfile, }) => {
        const NextBestActionProviders = useMemo(() => 
        // eslint-disable-next-line react/display-name
        ({ children }) => (React.createElement(Providers, { customer: customer, locale: locale }, children)), [customer, locale]);
        return (React.createElement(NextBestActionProviders, null,
            React.createElement(NextBestAction, { screenModalProviders: NextBestActionProviders, onCompleted: onCompleted, onNavigateToStyleProfile: onNavigateToStyleProfile, onReady: onReady })));
    };
    const hoc = sentryLoggerHOC({ logger });
    /**
     * It's important to not call sentryLoggerHOC each time this function gets called.
     * That's why its called outside this method.
     */
    return ({ customerId }) => ({
        NextBestActionRootHost: hoc({ customerId })(NextBestActionRootHost),
        NextBestActionRoot: hoc({ customerId })(NextBestActionRoot),
    });
};
export { nextBestActionRoot };
