var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ALIGN, COLOR, FLEX_DIRECTION, SPACE, useDevice } from '../../../hooks';
import { Icon, Text, View } from '../../primitives';
import { Theme } from '../../../theming';
import { style } from './TitleIcon.style';
export const TitleIcon = (_a) => {
    var { text, icon, color = COLOR.TEXT } = _a, rest = __rest(_a, ["text", "icon", "color"]);
    const { screen } = useDevice();
    const iconValue = (!icon.includes('small') && !screen.L ? `${icon}_small` : icon);
    const allIcons = Theme.get().iconGlyphs;
    const iconToShow = allIcons[iconValue] ? iconValue : icon;
    return (React.createElement(View, Object.assign({}, rest),
        React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW },
            React.createElement(Icon, { name: iconToShow, alignSelf: ALIGN.CENTER, color: color }),
            React.createElement(Text, { action: true, level: 3, alignSelf: ALIGN.CENTER, marginLeft: SPACE.SPACE_2, color: color, style: iconToShow.includes('small') ? style.textMobile : style.textDesktop }, text))));
};
TitleIcon.displayName = 'TitleIcon';
