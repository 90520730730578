import React, { useEffect, useState } from "react";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { ErrorBoundary, wrapComponent, wrapUseRoutes } from "./SentryDependencies";
import { LoggerProvider } from "./useLogger";
const sentryLoggerHOC = ({ logger }) => {
    logger.init();
    const useRoutes = wrapUseRoutes(reactRouterUseRoutes);
    return ({ customerId }) => (Component) => {
        const SentryLogger = (props) => {
            const [WrappedComponent, setWrappedComponent] = useState();
            useEffect(() => {
                logger.onMount(customerId);
                setWrappedComponent(() => wrapComponent(Component));
                return () => logger.onUnmount();
            }, []);
            return WrappedComponent ? (React.createElement(ErrorBoundary, null,
                React.createElement(LoggerProvider, { logger: logger },
                    React.createElement(WrappedComponent, { ...props, useRoutes: useRoutes })))) : null;
        };
        return SentryLogger;
    };
};
export { sentryLoggerHOC };
