import { viewTranslationKeyByLocale } from "../../../projection/translation/viewTranslationKeyByLocale";
import { locale as localeFunction } from "./locale";
import { translationSynced } from "./translationSynced";
const sync = ({ fetchTranslation, queryBus }) => async ({ aggregateRoot, command }) => {
    const aggregateId = command.aggregateId;
    const locale = localeFunction(command.locale);
    return {
        ...aggregateRoot,
        locale,
        key: await queryBus(viewTranslationKeyByLocale({ locale })),
        messages: await fetchTranslation({ locale }),
        domainEvents: [translationSynced({ aggregateId, locale })],
    };
};
export { sync };
