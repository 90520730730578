import { read as asyncStorageRead, write as asyncStorageWrite, baseBootstrap, } from "@lookiero/i18n";
import { bootstrap as messagingReactBootstrap } from "@lookiero/messaging-react";
import React, { useMemo } from "react";
import { useSyncTranslationForLocale } from "./behaviors/useSyncTranslationForLocale";
import { useViewTranslationForLocale } from "./behaviors/useViewTranslationForLocale";
const I18nConsumer = ({ domain, locale, children }) => {
    const [translation] = useViewTranslationForLocale({ locale, contextId: domain });
    const syncStatus = useSyncTranslationForLocale({ locale, contextId: domain });
    const value = useMemo(() => ({ locale, translation, syncStatus }), [locale, translation, syncStatus]);
    return children(value);
};
const i18nRoot = ({ fetchTranslation, read = asyncStorageRead, write = asyncStorageWrite, domain, }) => {
    const { Component: Messaging } = baseBootstrap({ messaging: messagingReactBootstrap({ id: domain }), domain })({
        fetchTranslation,
        read,
        write,
    });
    // eslint-disable-next-line react/display-name, react/prop-types
    return ({ locale, children, includeDevTools }) => (React.createElement(Messaging, { cacheClearOnUnmount: false, includeReactQueryDevTools: includeDevTools },
        React.createElement(I18nConsumer, { domain: domain, locale: locale }, (value) => children(value))));
};
export { i18nRoot };
