import React from "react";
import { Pressable } from "react-native";
import { Text, Thumbnail } from "@lookiero/aurora";
import { AnimatedChecked } from "../animatedChecked/AnimatedChecked";
import { style } from "./OptionImage.style";
const OptionImage = ({ checked, children, disabled, height, imageResizeMode, src, srcSet, title, value, width, style: customStyle, onChange, ...others }) => (React.createElement(Pressable, { ...others, style: [style.containerBase, { height, width }, customStyle], onPress: !disabled && onChange ? () => onChange(value) : undefined },
    React.createElement(Thumbnail, { height,
        src,
        srcSet,
        resizeMode: imageResizeMode,
        style: [style.containerBase, customStyle],
        width }),
    React.createElement(Text, { hidden: true }, title),
    React.createElement(AnimatedChecked, { checked: checked }),
    children));
export { OptionImage };
