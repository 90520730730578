import { request } from "@lookiero/data-sources";
import { COMPLETE_YOUR_LOOK_BASE_URL, CATEGORIES_BASE_URL, ONBOARDING_BASE_URL, SALES_BASE_URL, USERS_BASE_URL, TRENDING_FOR_YOU_BASE_URL, } from "../../ui/shared/config";
class UserRepositoryImpl {
    static instance;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { }
    static getInstance() {
        if (!UserRepositoryImpl.instance) {
            UserRepositoryImpl.instance = new UserRepositoryImpl();
        }
        return UserRepositoryImpl.instance;
    }
    async hasCompleteYourLookAccess(auth) {
        try {
            const { active = false } = await request({
                endpoint: `${COMPLETE_YOUR_LOOK_BASE_URL}/has-access`,
                headers: { Authorization: auth },
                method: "POST",
            });
            return active;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async hasCategoriesAccess(auth) {
        try {
            const { active = false } = await request({
                endpoint: `${CATEGORIES_BASE_URL}/has-access`,
                headers: { Authorization: auth },
                method: "POST",
            });
            return active;
        }
        catch (error) {
            throw new Error("hasCategoriesAccess");
        }
    }
    async hasSalesAccess(auth) {
        try {
            const { active = false } = await request({
                endpoint: `${SALES_BASE_URL}/has-access`,
                headers: { Authorization: auth },
                method: "POST",
            });
            return active;
        }
        catch (error) {
            console.log(error);
            throw new Error("hasSalesAccess");
        }
    }
    async hasTrendingForYouAccess(auth) {
        try {
            const { active = false } = await request({
                endpoint: `${TRENDING_FOR_YOU_BASE_URL}/has-access`,
                headers: { Authorization: auth },
                method: "POST",
            });
            return active;
        }
        catch (error) {
            console.log(error);
            throw new Error("hasTrendingForYouAccess");
        }
    }
    async hasPurchase(auth) {
        try {
            const { active = false } = await request({
                endpoint: `${USERS_BASE_URL}/has-purchase`,
                headers: { Authorization: auth },
            });
            return active;
        }
        catch (error) {
            console.log(error);
            throw new Error("hasPurchase");
        }
    }
    async logLastAccess(auth) {
        try {
            await request({
                endpoint: `${ONBOARDING_BASE_URL}/log-last-access`,
                headers: { Authorization: auth },
                method: "PUT",
            });
        }
        catch (error) {
            console.log(error);
            return Promise.reject("logLastAccess");
        }
    }
}
export { UserRepositoryImpl };
