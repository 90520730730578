const toTranslationProjection = (translationDto) => ({
    id: translationDto.id,
    key: translationDto.key,
    messages: translationDto.messages,
});
const storageTranslationByLocaleView = ({ read }) => async ({ translationKey }) => {
    const translationDto = await read(translationKey);
    return translationDto && toTranslationProjection(translationDto);
};
export { storageTranslationByLocaleView };
