/* eslint-disable @typescript-eslint/naming-convention */
import { PAYMENTS_CONFIG } from "../config";
const LOOK_IN_DOMAIN = "LOOK_IN";
const LOOK_IN_VIEW = {
    BASKET: "basket",
    CART: "cart",
    CATEGORIES: "categories",
    CHECKOUT_CONFIRMATION: "checkout-confirmation",
    CHECKOUT: "checkout",
    HOME: "home",
    ORDER_DETAIL: "order-detail",
    ORDERS: "orders",
    PRODUCT_DETAIL: "product-detail",
    RESET: "reset",
    SALES: "sales",
    THANK_YOU: "thankyou",
    TRENDING_FOR_YOU: "trending-for-you",
};
const LOOK_IN_EVENT = {
    AFTER_CHECKOUT_ACCESS_GRANTED: "after_checkout_access_granted",
    ANCHOR_ITEM_LIST: "anchor_item_list",
    BACK_TO_TOP: "shop_backtotop",
    BASKET_DISPLAYED: "view_cart",
    BASKET_ITEM_ADDED: "addToCartFromSelectedGarment",
    BASKET_ITEM_REMOVED: "removeFromCart",
    CATEGORIES_LOAD: `${LOOK_IN_VIEW.CATEGORIES}:load`,
    CATEGORIES_SELECTED: "shop_click_cat",
    CATEGORY_TAB_SELECTED: "shop_click_cat_tab",
    CHECKOUT_ITEM_REMOVED: `${LOOK_IN_VIEW.CHECKOUT}:item-removed`,
    CHECKOUT_LOAD: `${LOOK_IN_VIEW.CHECKOUT}:load`,
    CHECKOUT_PAYMENT_INFO_ADDED: `${LOOK_IN_VIEW.CHECKOUT}:payment-info-added`,
    CHECKOUT_SOLD_OUT: "sold_out",
    CHECKOUT_STARTED: "begin_checkout",
    CHECKOUT_SUCCESS: "purchase",
    CLOSE_STOCK_OUT_MODAL: "close_stock_out_modal",
    COMPLETE_YOUR_LOOK_SELECTED: "shop_click_cyl",
    EMPTY_STATE: "empty_state",
    HOME_LOAD: `${LOOK_IN_VIEW.HOME}:load`,
    ITEM_DETAIL_DISPLAYED: "view_item",
    ITEM_DETAIL_SOLD_OUT: "garment_sold_out",
    ITEM_SELECTED: "select_item",
    LOOK_AND_LIKE_BANNER_CLICKED: "lookandlike_clicked",
    NOTIFY_STOCK_BUTTON_CLICKED: "notify_stock_button_clicked",
    OPEN_HOST_APP_MENU: "open-host-app-menu",
    ORDERS_LOAD: `${LOOK_IN_VIEW.ORDERS}:load`,
    PAYMENT: "payment",
    PRODUCT_DETAIL_LOAD: `${LOOK_IN_VIEW.PRODUCT_DETAIL}:load`,
    PROMOCODE_INVALID: "PromocodeFailed",
    PROMOCODE_VALID: "PromocodeApplied",
    REDIRECT_TO_CATEGORIES_FROM_CYL: "redirect_to_categories_from_cyl",
    REMOVE_AND_PAY_STOCK_OUT_MODAL: "remove_and_pay_stock_out_modal",
    SALES_LOAD: `${LOOK_IN_VIEW.SALES}:load`,
    SELECT_ANCHOR_GARMENT: "select_anchor_garment",
    SHOP_BUTTON_CLICKED: "shop_click_button_1",
    SHOP_EDIT_SHIPPING_INFO: "shop_edit_shipping_info",
    SHOW_NOTIFY_STOCK_BUTTON: "show_notify_stock_button",
    SHOW_STOCK_OUT_MODAL: "show_stock_out_modal",
    TRENDING_FOR_YOU_LOAD: `${LOOK_IN_VIEW.TRENDING_FOR_YOU}:load`,
    TRENDING_FOR_YOU_SELECTED: "shop_click_t4u",
    VIEW_ITEM_LIST: "view_item_list",
    VIEW_WISHLIST: "view_wishlist",
    WISHLIST_ITEM_ADDED: "add_to_wishlist",
    WISHLIST_ITEM_REMOVED: "remove_from_wishlist",
    ...Object.keys(PAYMENTS_CONFIG.CheckoutStatus).reduce((acc, statusKey) => {
        const formattedStatus = PAYMENTS_CONFIG.CheckoutStatus[statusKey].replace(/_/g, "-");
        return {
            ...acc,
            [`CHECKOUT_PAYMENT_${statusKey.toUpperCase()}`]: `${LOOK_IN_VIEW.CHECKOUT}:payment-${formattedStatus.toLowerCase()}`,
        };
    }, {}),
};
export { LOOK_IN_DOMAIN, LOOK_IN_EVENT, LOOK_IN_VIEW };
