import React, { useEffect, useRef } from "react";
import { Spinner } from "@lookiero/aurora";
import { AnswersProvider, AnswersValidationProvider, FocusedInputProvider, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { useViewScreenBySlugAndCustomerId } from "../../../projection/screen/react/useViewScreenBySlugAndCustomerId";
import { useViewStyleProfileAnswersByCustomerId } from "../../../projection/styleProfileAnswers/react/useViewStyleProfileAnswersByCustomerId";
import { useTrackScreenModalPageView } from "../../../tracking/useTrackScreenModalPageView";
import { useTrackScreenPageView } from "../../../tracking/useTrackScreenPageView";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { style } from "./Screen.style";
import { QuestionForm } from "./components/questionForm/QuestionForm";
const Screen = ({ slug, onSuccess, scrollRef, onScreenLoaded }) => {
    const { customer: { customerId, country, segment }, androidInAppBrowser, } = useStaticInfo();
    const [answers, answersStatus] = useViewStyleProfileAnswersByCustomerId({ customerId });
    /**
     * In order to solve the "unmount" transition, this view will allways be rendered
     * under the path ":slug?", setting as visible or not the Modal.
     *
     * Mantaining a reference to the previous question will allow us to hide the Modal while
     * rendering the previous (invalidated) screen projection.
     */
    const [screen] = useViewScreenBySlugAndCustomerId({ slug, customerId, experiment: undefined });
    const screenRef = useRef(screen);
    screenRef.current = slug ? screen : screenRef.current;
    useEffect(() => {
        if (!screen) {
            return;
        }
        onScreenLoaded({ screen });
    }, [onScreenLoaded, screen]);
    useTrackScreenPageView({
        country,
        segment,
        slug: screen?.metadata.slug,
        customerId,
    });
    const trackSizeGuideModalPageView = useTrackScreenModalPageView({
        country,
        segment,
        slug: "size",
        customerId,
    });
    const trackSilhouetteGuideModalPageView = useTrackScreenModalPageView({
        country,
        segment,
        slug: "silhouette",
        customerId,
    });
    return screenRef.current && answers ? (React.createElement(AnswersProvider, { answers: answers, answersStatus: answersStatus, questions: [screenRef.current] },
        React.createElement(AnswersValidationProvider, { contextId: MESSAGING_CONTEXT_ID, scrollRef: scrollRef },
            React.createElement(FocusedInputProvider, { enabled: androidInAppBrowser, scrollRef: scrollRef },
                React.createElement(QuestionForm, { question: screenRef.current, onOpenSilhouetteGuide: trackSilhouetteGuideModalPageView, onOpenSizeGuide: trackSizeGuideModalPageView, onSuccess: onSuccess }))))) : (React.createElement(Spinner, { style: style.spinner, testID: "spinner" }));
};
export { Screen };
