import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, colorBgInteractiveActive, space4, space6 } = theme();
const style = StyleSheet.create({
    button: {
        alignItems: "center",
        backgroundColor: colorBgInteractiveActive,
        borderRadius: borderRadius3,
        flex: 1,
        gap: space6,
        justifyContent: "center",
        padding: space4,
    },
    text: {
        padding: 0,
        textAlign: "center",
    },
});
export { style };
