import { Platform, StyleSheet } from "react-native";
const style = StyleSheet.create({
    bullets: {
        bottom: 0,
        justifyContent: "center",
        position: "absolute",
        width: "100%",
    },
    carousel: {
        flex: 1,
    },
    carouselItem: {
        flex: 1,
    },
    carouselTrack: {
        flex: 1,
        flexDirection: "row",
        height: "100%",
    },
    container: {
        flex: 1,
        overflow: "hidden",
        ...Platform.select({
            web: {
                transform: [{ translateZ: 0 }],
            },
        }),
    },
    pointer: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cursor: "pointer",
    },
});
export { style };
