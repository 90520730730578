import { BrowserClient, Scope, getDefaultIntegrations } from "@sentry/browser";
import { ErrorBoundary, reactRouterV6BrowserTracingIntegration, wrapUseRoutes } from "@sentry/react";
import { useEffect } from "react";
import { matchRoutes, useLocation, useNavigationType, createRoutesFromChildren } from "react-router-native";
/**
 * BrowserTracing must be initialized before the BrowserClient
 * so we instantiate it before the integrations function is called
 */
const browserTracing = reactRouterV6BrowserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
});
// filter integrations that use the global variable
const nonGlobalIntegrations = getDefaultIntegrations({}).filter((defaultIntegration) => !["BrowserApiErrors", "Breadcrumbs", "GlobalHandlers"].includes(defaultIntegration.name));
const integrations = () => [...nonGlobalIntegrations, browserTracing];
const wrapComponent = (Component) => Component;
export { BrowserClient as Client, ErrorBoundary, Scope, integrations, wrapComponent, wrapUseRoutes };
