var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { COLOR, FLEX_DIRECTION, POINTER, SIZE, SPACE } from '../../../hooks';
import { Icon, Text, Touchable, VIEW_ROLES, View } from '../../primitives';
import { style } from './Accordion.style';
const Accordion = forwardRef((_a, ref) => {
    var { active = false, children, disabled = false, divider = true, text, title, onPress } = _a, others = __rest(_a, ["active", "children", "disabled", "divider", "text", "title", "onPress"]);
    return (React.createElement(View, Object.assign({}, others, { collapsable: false, role: VIEW_ROLES.article, ref: ref }),
        React.createElement(Touchable, { paddingVertical: text ? SPACE.SPACE_2 : SPACE.SPACE_5, pointerEvents: disabled ? POINTER.NONE : undefined, style: [style.touchable, divider && style.divider], tabIndex: 0, wide: true, onPress: !disabled && onPress ? () => onPress(!active) : undefined },
            React.createElement(View, { flexDirection: FLEX_DIRECTION.COLUMN, marginRight: SPACE.SPACE_4, flex: SIZE.XS },
                React.createElement(Text, { action: true, color: disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT, wide: true }, title),
                !!text && (React.createElement(Text, { color: disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT_MEDIUM, ellipsizeMode: !active, style: style.text }, text))),
            React.createElement(View, { style: text && style.iconContainerWithText },
                React.createElement(Icon, { name: active ? 'collapse' : 'expand', color: disabled ? COLOR.ICON_DISABLED : COLOR.ICON_LIGHT }))),
        active && React.createElement(View, { role: VIEW_ROLES.section }, children)));
});
Accordion.displayName = 'Accordion';
export { Accordion };
