import { useCallback, useEffect, useState } from "react";
import { UserRepositoryImpl } from "../../domain/userRepositoryImpl/UserRepositoryImpl";
const userRepo = UserRepositoryImpl.getInstance();
const useEnabledOfferings = ({ authToken }) => {
    const [enabledOfferings, setEnabledOfferings] = useState();
    const fetchEnabledOfferings = useCallback(async () => {
        if (authToken) {
            const accessesPromises = [
                userRepo.hasCompleteYourLookAccess(authToken).catch(() => false),
                userRepo.hasCategoriesAccess(authToken).catch(() => false),
                userRepo.hasSalesAccess(authToken).catch(() => false),
                userRepo.hasTrendingForYouAccess(authToken).catch(() => false),
            ];
            try {
                const data = await Promise.all(accessesPromises);
                setEnabledOfferings({
                    completeYourLook: Boolean(data[0]),
                    categories: Boolean(data[1]),
                    sales: Boolean(data[2]),
                    trendingForYou: Boolean(data[3]),
                });
            }
            catch (error) { }
        }
    }, [authToken]);
    useEffect(() => {
        fetchEnabledOfferings();
    }, [fetchEnabledOfferings]);
    return { enabledOfferings, fetchEnabledOfferings };
};
export { useEnabledOfferings };
