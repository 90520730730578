import { defaultStackParser, makeFetchTransport } from "@sentry/browser";
import { Platform } from "react-native";
import { Client, Scope, integrations } from "./SentryDependencies";
// This configuration is obtained from
// https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
const IGNORED_ERRORS = [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
    // Avast extension error
    "_avast_submit",
];
const DENIED_URLS = [
    // Kameleoon
    /data\.kameleoon\.io/i,
    // TikTok
    /analytics\.tiktok\.com/i,
    /i18n\/pixel\//i,
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
const sentryLogger = (environment) => {
    let client;
    let scope;
    const init = () => {
        const { environment: clientEnvironment, release, publicKey, project, enableIntegrations = true } = environment();
        if (!(clientEnvironment && release && publicKey && project)) {
            return;
        }
        client = new Client({
            environment: clientEnvironment,
            release,
            dsn: `https://${publicKey}@o179049.ingest.sentry.io/${project}`,
            integrations: enableIntegrations ? integrations() : [],
            tracesSampleRate: 0.5,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            enableNative: Platform.OS !== "web",
            autoInitializeNativeSdk: Platform.OS !== "web",
            stackParser: defaultStackParser,
            transport: makeFetchTransport,
            ignoreErrors: IGNORED_ERRORS,
            denyUrls: DENIED_URLS,
        });
        scope = new Scope();
        scope.setClient(client);
        client.init();
    };
    const onMount = (customerId) => {
        scope === null || scope === void 0 ? void 0 : scope.setUser({ id: customerId });
    };
    const onUnmount = () => {
        scope === null || scope === void 0 ? void 0 : scope.setUser(null);
    };
    const captureException = (error) => {
        scope === null || scope === void 0 ? void 0 : scope.captureException(error);
    };
    return {
        init,
        onMount,
        onUnmount,
        captureException,
    };
};
export { sentryLogger };
