const fromDomain = (translation) => ({
    id: translation.aggregateId,
    key: translation.key,
    locale: translation.locale,
    messages: translation.messages,
});
const getTranslation = () => async () => {
    throw new Error("Translation not found");
};
const saveTranslation = ({ write }) => async (aggregateRoot) => await write(aggregateRoot.key, fromDomain(aggregateRoot));
export { getTranslation, saveTranslation };
