import StyleSheet from 'react-native-extended-stylesheet';
const borderRadiusVariantStyleRecord = {
    rounded: {
        borderRadius: '$borderRadius3',
    },
    squared: {
        borderRadius: 0,
    },
};
const borderRadiusVariantStyle = StyleSheet.create(borderRadiusVariantStyleRecord);
const contentAlignStyleRecord = {
    left: {
        justifyContent: 'flex-start',
    },
    center: {
        justifyContent: 'center',
    },
};
const contentAlignStyle = StyleSheet.create(contentAlignStyleRecord);
const style = StyleSheet.create({
    container: {
        padding: '$space3',
    },
    textContainer: {
        flexShrink: 1,
    },
    paddingTop: {
        paddingTop: '$space1',
    },
});
export { style, borderRadiusVariantStyle, contentAlignStyle };
