/**
 * credentials: "include" and mode: "no-cors" are required
 * for the browser's cache to work properly.
 */
const request = async ({ endpoint, locale }) => (await (await fetch(endpoint(locale), {
    method: "GET",
    credentials: "include",
    mode: "no-cors",
    referrer: "",
})).json());
const fetchFetchTranslation = ({ endpoint }) => async ({ locale }) => request({ endpoint, locale });
export { fetchFetchTranslation };
