var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useState } from 'react';
import { Platform } from 'react-native';
import { testID as getTestID } from '../../../helpers';
import { COLOR, DISPLAY, POINTER, POSITION, SIZE, styles, useBanProps, useBanStylerProps, useId } from '../../../hooks';
import { Icon, Motion, Text, View } from '../../primitives';
import { Theme } from '../../../theming';
import { useField } from './helpers';
import { BANNED_PROPS } from './InputField.definition';
import { HOC } from './InputField.hoc';
import { style } from './InputField.style';
import { SPACE } from '../../../hooks/useStyler/styler.definition';
const MOTION_M_DELAY = 300;
const isAndroid = Platform.OS === 'android';
const isIos = Platform.OS === 'ios';
const InputField = forwardRef((_a, ref) => {
    var { animatedLabel = true, disabled = false, error = false, fieldset, hint, icon, id, label, multiLine = false, name, style: inheritStyle, type, onBlur, onChange, onFocus, options, placeholder, testID } = _a, others = __rest(_a, ["animatedLabel", "disabled", "error", "fieldset", "hint", "icon", "id", "label", "multiLine", "name", "style", "type", "onBlur", "onChange", "onFocus", "options", "placeholder", "testID"]);
    const idField = useId(id);
    const { handleChange, filled } = useField(Object.assign({ onChange }, others));
    const [focus, setFocus] = useState(false);
    const [timeoutID, setTimeoutID] = useState(undefined);
    const handleBlur = (event) => {
        if (!focus)
            return;
        if (event && event.persist)
            event.persist();
        setFocus(false);
        setTimeoutID(onBlur ? setTimeout(() => onBlur(event), MOTION_M_DELAY) : undefined);
    };
    const handleFocus = (event) => {
        if (timeoutID)
            clearTimeout(timeoutID);
        if (!focus) {
            setFocus(true);
            if (onFocus)
                onFocus(event);
        }
    };
    const { space025, space05, space1, space2, space4 } = Theme.get();
    const empty = !filled && !focus;
    let color = COLOR.TEXT_MEDIUM;
    if (disabled)
        color = COLOR.TEXT_DISABLED;
    else if (error)
        color = COLOR.TEXT_ERROR;
    else if (focus)
        color = COLOR.TEXT;
    const labelTextFont = empty && !error ? { body: true, level: 3 } : { detail: true, level: 2 };
    return (React.createElement(View, Object.assign({}, useBanProps(others, [...BANNED_PROPS, 'accessibilityLabel']), { style: styles(style.inputField, focus && style.focus, disabled && style.disabled, empty && !error && style.empty, error && style.error, (filled || error) && style.filled, inheritStyle) }),
        React.createElement(View, Object.assign({ backgroundColor: COLOR.BG_BASE, borderColor: error
                ? COLOR.BORDER_INPUT_ERROR
                : disabled
                    ? COLOR.BORDER_INPUT
                    : focus
                        ? COLOR.BORDER_INPUT_FOCUS
                        : COLOR.BORDER_INPUT, position: POSITION.RELATIVE, style: style.border }, getTestID(testID)),
            React.createElement(HOC, Object.assign({}, useBanStylerProps(others), getTestID(testID), { color: color, disabled: disabled, error: error, focus: focus, fieldset: fieldset, id: idField, label: label, name: name, multiLine: multiLine, onBlur: handleBlur, onChange: handleChange, onFocus: handleFocus, options: options, position: POSITION.RELATIVE, placeholder: placeholder, ref: ref, style: styles(style.content, multiLine && style.multiline, empty && !error && !placeholder && style.contentEmpty), type: type })),
            label && animatedLabel ? (React.createElement(Motion, { backgroundColor: COLOR.BG_BASE, color: color, display: DISPLAY.BLOCK, layer: SIZE.XS, marginLeft: SPACE.SPACE_4, pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: styles(style.containerLabel, empty && !error && style.labelEmpty), value: {
                    translateY: empty && !error
                        ? Number(space4) + Number(space05)
                        : (Number(space2) + Number(isAndroid || isIos ? space025 : 0)) * -1,
                    translateX: empty && !error ? 0 : -Number(space1),
                } },
                React.createElement(Text, Object.assign({}, labelTextFont, { color: color, ellipsizeMode: true, htmlFor: idField }), label))) : (label &&
                empty &&
                !error && (React.createElement(View, { marginLeft: SPACE.SPACE_4, pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: [
                    style.containerLabel,
                    style.labelEmpty,
                    {
                        transform: [{ translateY: Number(space4) }, { translateX: 0 }],
                    },
                ] },
                React.createElement(Text, Object.assign({}, labelTextFont, { color: color, ellipsizeMode: true, htmlFor: idField }), label)))),
            icon && (React.createElement(Icon, { color: color, name: icon, pointerEvents: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.icon }))),
        !!hint && (React.createElement(Text, { color: color, detail: true, display: DISPLAY.INLINE_BLOCK, level: 2, marginTop: SPACE.SPACE_1 }, hint))));
});
InputField.displayName = 'InputField';
export { InputField };
